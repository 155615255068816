import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 1.45rem 1.0875rem;
`

const MarkedHeader = styled.h1`
  font-family: "Lato";
  display: inline;
  line-height: 3rem;
  border-radius: 0.1em;
  padding: 0 0.25em 0 0;
`

const HeaderDate = styled.h3`
  margin-top: 10px;
  color: #606060;
`

// STYLE THE TAGS INSIDE THE MARKDOWN HERE
const MarkdownContent = styled.div`
  a {
    text-decoration: none;
    position: relative;

    background-image: linear-gradient(
      #9effb8,
      #9effb8
    );

    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 100%;
    transition: background-size 0.1s linear;

    &:hover {
      background-size: 100% 100%;
    }
  }
`

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Content>
        <MarkedHeader>{post.frontmatter.title}</MarkedHeader>
        <HeaderDate>
          Written {post.frontmatter.date} by {post.frontmatter.author} <br/>{post.fields.readingTime.text} | {post.fields.readingTime.words} words
        </HeaderDate>
        <MarkdownContent dangerouslySetInnerHTML={{ __html: post.html }} />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        path
        title
        author
      }
      fields {
        readingTime {
          text
          words
        }
      }
    }
  }
`
